.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.table-bordered {
  border: 1px solid #ddd;
}

.tablebox {
  margin-top: 10px;
}

.tablebox thead {
  border: 1px solid #ddd;
  background: #262364;
  white-space: nowrap;
}

.tablebox th {
  color: #fff;
}

.btitle {
  color: #262364;
  text-decoration: none;
}

.tablebox th,
.tablebox td {
  text-align: center;
}

.tablebox:last-child td {
  border: 1px solid #ddd !important;
}

.tablebox td {
  padding: 5px 5px;
}
.w-300 {
  width: 300px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.drop-file-input__label p {
  color: #4267b2;
}

.drop-file-input {
  margin-bottom: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.graybox {
  background-color: #ebecf0;
  padding: 6px 5px 12px 18px;
  border-radius: 5px;
  margin-bottom: 30px;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

svg {
  display: inline-block;
  vertical-align: top;
}

h1 {
  font-weight: 900;
  font-size: 20px;
  line-height: 1;
  margin: 6px 0 6px 10px;
  display: inline-block;
  vertical-align: top;
}

/* MUI Start */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #203864;
  border-radius: 20px;
  border: 3px solid #fff;
}

.MuiListItemIcon-root>svg {
  color: #fff;
}

.MuiMenuItem-root>a>div>span,
.MuiMenuItem-root>div>span {
  font-size: 1rem;
  color: #203864;
}

.MuiListItemText-root>span {
  font-size: 0.85rem;
  color: #fff;
}

/* MUI End */

/* Modal css start */
/* .MuiPaper-root{
  width: 500px;
  height: 500px;
} */

input[type=file]::file-selector-button {
  border: 2px solid #1565c0;
  padding: 14px 15px;
  border-radius: 4px 0 0 4px;
  background-color: #1565c0;
  transition: 1s;
  color: #fff;
}

input[type=file]::file-selector-button:hover {
  background-color: #1565c0;
  border: 2px solid #1565c0;
}

.input-file {
  border: 1px solid #c6c3c3;
  border-radius: 4px;
  height: 57px;
  width: 100%;
  line-height: 25px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 16px;
}

.modal-title {
  text-align: center;
  font-size: 22px;
  margin-bottom: 4px;
}

.input-box {
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  outline: none;
}

.serach-icon {
  background-color: #1565c0 !important;
  border: 1px solid #1565c0 !important;
  height: 50px;
  width: 45px;
  border-radius: 0 4px 4px 0 !important;
  position: absolute !important;
  right: 0;
  cursor: pointer;
  color: #fff !important;
  outline: none;
}

.input-posi {
  position: relative;
}

.modal-btn {
  background-color: #1565c0;
  border: 1px solid #1565c0;
  height: 40px;
  width: 90px;
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
}

.MuiDialogActions-root {
  padding: 20px 15px !important;
  border-top: 1px solid #ddd;
}

.choose-img img {
  height: 150px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #1565c0;
  margin-bottom: 15px;
}

.target-tab .MuiButtonBase-root {
  text-transform: capitalize;
  font-weight: 600;
  padding: 15px 23px;
}

.location-icon {
  position: absolute !important;
  background-color: #1565c0 !important;
  border: 1px solid #1565c0 !important;
  border-radius: 3px 0 0 3px !important;
  height: 50px;
  color: #fff !important;
  width: 50px;
  left: -1px;
}

.location .input-box {
  padding-left: 63px;
}

.anywhere img {
  height: 134px;
  width: 140px;
}

.dialog>div>.MuiPaper-root {
  width: 490px;
  height: 600px;
}

/* Modal css End */

/* Loding Spinner Start */
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  /* width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite; */
  z-index: 1;
  border: 8px solid #fcfcfc;
  border-radius: 50%;
  border-top: 8px solid #203864;
  width: 55px;
  height: 55px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  position: fixed;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.spinner-container {
  /* display: grid; */
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  width: 100%;
  z-index: 9999;
  left: 0;
  top: 0;
  background-color: rgba(17, 43, 82, .7);
}

/* Loding Spinner End */

.MuiOutlinedInput-root input {
  background-color: #fff;
}

.mt20 {
  margin-top: 15px;
}

.datalabel {
  display: inline-block;
  border: solid 1px #1976d2;
  width: 100%;
  padding: 10.5px 14px;
  border-radius: 5px;
}

.dataicon {
  margin-top: 12px;
  padding: 0px 10px !important;
  background: #1976d2;
  border-radius: 5px;
  color: #fff
}

@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.pagination {
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin: 10px;
  padding: 5px;
}

.pagination li a {
  border: 2px solid #1976d2 !important;
  padding: 1rem 1.5rem !important;
  color: #1976d2;
}

.pagination .disabled {
  cursor: not-allowed !important;
}

.fileuploadbox {
  height: 150px !important;
  margin-top: 40px !important;
  padding: 15px !important;
  margin: 15px auto;
}

.fileuploadbox svg {
  width: 40px !important;
  height: 40px !important;
}

.fileuploadbox span {
  font-size: 15px !important;
  padding-left: 5px;
}

.fixedplusbtn {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  width: 50px !important;
  border: 3px solid #1976d2 !important;
  font-size: 20px !important;
  margin-right: 20px !important;
  margin-bottom: 10px !important;
  height: 50px !important;
}

.uploadswitch {
  text-align: center !important;
}


.profileblock .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
  margin-top: 48px !important;
}

.toggle {
  position: relative;
  box-sizing: border-box;
}

.toggle input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.toggle label {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.toggle label:before {
  content: '';
  width: 75px;
  height: 42px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 46px;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}

.toggle label:after {
  content: '';
  position: absolute;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: #fff;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}

.toggle input[type="checkbox"]:checked+label:before {
  background: #4BD865;
}

.toggle input[type="checkbox"]:checked+label:after {
  left: 35px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 240px;
  background: #fff;
  transition: all 0.5s ease;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 9;
}

.sidebar button {
  background-color: #22abe1 !important;
  color: #fff;
  height: 40px;
  width: 40px;
  text-align: center;
  position: absolute;
  right: 7px;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #22abe1;
}

.sidebar ul {
  height: 100vh;
  background-color: #22abe1;
}

.sidebar li {
  list-style-type: none;
  font-size: 17px;
  line-height: 30px;
}

.sidebar .active {
  background-color: #262364;
}

.sidebar img {
  height: 35px;
  width: 150px;
  margin: 15px 15px;
  display: block;
}

.sidebar ul {
  height: 100vh;
  background-color: #22abe1;
  padding: 15px;
}

.sidebar li a {
  color: #fff;
  text-decoration: none;
  padding: 10px 20px;
  width: 100%;
  display: block;
}

.launcher {
  padding-top: 0px;
  width: 100%;
}

.padleft {
  padding-left: 250px !important;
}

.deviceblock {
  padding-top: 25px;
  border-top: 1px solid #ddd;
  margin-top: 25px;
}

.MuiButton-root {
  font-size: 13px !important;
  font-weight: bold !important;
}

.bg-color {
  background-color: #ffffff;
}

.contentbox {
  background-color: #fff;
  border-radius: 4px;
  padding-top: 20px;
}

.contentbox .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
  width: 50px;
  height: 50px;
}

.launcher .css-zpcwqm-MuiStepConnector-root {
  top: 25px;
  left: calc(-48% + 18px);
  right: calc(51% + 21px);
}

.step1 .css-i4bv87-MuiSvgIcon-root {
  display: none;
}

.dropdownbox .css-e363es-MuiFormControl-root {
  width: 100%;
}

.stepbtn {
  background-color: #22abe1;
  padding: 20px;
  height: 40px;
  line-height: 0px;
  border: 1px solid #22abe1;
  color: #fff;
  border-radius: 3px;
  margin-left: 10px;
}

.successfullyblock {
  padding: 50px;
  text-align: center;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  width: 450px;
  margin: 0 auto;
}

.successfullyblock img {
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
}

.tablebtn {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  border: 2px solid #22abe1;
  color: #22abe1;
}


.topbox {
  background-color: #e6f6fd;
  margin-bottom: 30px;
  padding: 20px 20px 20px 20px;
  border-radius: 4px;
}

.Loginbox {
  padding-top: 120px;
}

.topbox p {
  margin-bottom: 0;
}


.backbox {
  text-align: center;
  display: block;
  width: 100%;
  margin: 5px 0;
}

.backbox a {
  text-decoration: none;
}

.Loginbox h1 {
  margin-top: 20px;
  font-size: 18px;
  color: #1976d2;
  font-weight: 500;
}

.Loginbox .loginlogo {
  margin-top: 30px;
}

.Loginbox form {
  padding-bottom: 20px;
}

.profilebox .css-i4bv87-MuiSvgIcon-root {
  font-size: 35px;
}

.zidex {
  z-index: 1;
}

.appicon {
  width: 50px;
  height: 50px;
}

.content {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 22px;
  max-height: 123px;
  max-width: 100%;
  min-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.otpmodal .MuiOutlinedInput-root input {
  border: 1px solid #ddd;
  border-radius: 5px;
}

.otpmodal .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none;
}

.otpmodal.dialog>div>.MuiPaper-root {
  width: auto;
  height: auto;
  border-radius: 25px;
}

.rotp {
  text-decoration: none;
  font-weight: 600;
}

.wrapperbox {
  width: 100%;
  display: flex;
  overflow-y: auto;
}

.otpinput input {
  width: 60px !important;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 60px;
}

.otpinput span {
  color: #c3bfbf;
  padding: 3px;
  font-size: 17px;
}

.dailogicon {
  height: 30px;
  width: 35px;
  margin-right: 0 !important;
}

.libearloader {
  left: 0;
  top: 0;
  color: #1fbe0f;
  width: 100%;
  z-index: 9;
}

.circleloader {
  z-index: 9;
  margin: 0 auto;
  display: block !important;
  top: 50%;
  position: absolute;
  left: 0;
  right: 0;
}

.success .MuiSnackbarContent-root {
  background: #edf7ed !important;
  color: #1e4620 !important;
}

.info .MuiSnackbarContent-root {
  background: #e5f6fd !important;
  color: #014361 !important;
}

.warning .MuiSnackbarContent-root {
  background: #fff4e5 !important;
  color: #663c00 !important;
}

.error .MuiSnackbarContent-root {
  background: #fdeded !important;
  color: #5f2120 !important;
}

.release-active {
  color: #fff;
  background-color: #22abe1;
}

.profileblock li.active {
  background-color: #262364;
}

.profileblock li.active span {
  color: #fff !important;
}

@media (max-width: 991px) {
  .launcher {
    padding: 15px;
    width: 100%;
    position: absolute;
    left: 0;
  }
}