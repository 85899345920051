.list-cards {
  width: 100%;
  background-color: #ebecf0;
  margin-right: 0.5rem;
  border-radius: 5px;
  margin-bottom: 30px;
  max-height: 400px;
  height: 600px;
  min-height: 100px;
  overflow-y: scroll;

  .title-list {
    padding: 1rem 1rem 0;
  }

  .container-cards {
    overflow-x: auto;
   // max-height: 55vh;
    padding: 0 1rem;
  }

  .card-container {
    overflow-y: hidden;
   // margin: 0.5rem 0;
  }
}
.editable-title-container h2 {
  font-size: 17px;
  font-weight: bold;
  text-transform: capitalize;
}
.card-container p {
  font-size: 15px;
}