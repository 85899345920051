.onoffswitch {
  position: relative;
  width: 100px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.activeblock, .deactiveblock {
  position: relative;
  width: 100px;
  margin-bottom: 20px;
  cursor: pointer;
}

.activecolor {
  position: relative;
  color: #fff;
  padding: 5px 20px;
  cursor: pointer;
}

.onswitch-switch-button {
  display: block;
  width: 21px;
  height: 21px;
  background: #FFFFFF;
  border-radius: 20px;
  margin: 4px 5px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  cursor: pointer;
}

.deactiveblock .onswitch-switch-button {
  left: 0;
}

.activeblock .activecolor, .deactiveblock .activecolor {
  position: absolute;
  cursor: pointer;
  left: 0;
  background-color: #17ac2c;
  color: #fff;
  text-align: left;
  height: 30px;
  content: "";
  width: 100px;
  display: block;
  border-radius: 30px;
  padding-left: 15px;
  line-height: 18px;
  font-size: 15px;
}

.deactiveblock .activecolor {
  padding-left: 35px;
  background-color: #17ac2c;
}